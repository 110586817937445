import {
  MEDIA_CLICKED,
  MEDIA_PLAYING,
  onEvent,
} from '@fec/assets/js/utils/event';
import { KEYCODES } from '@fec/frontend/foundation/utils/keycodes';
import { SrfLetterboxPlayer } from '@aron/common/SrfLetterboxPlayer';

const MEDIA_HOOK = '.js-media';

export class SrfMediaWindow {
  constructor(element) {
    this.element = element;
    this.$playerContainer = $('.media-window__player', this.element);
    this.CLOSE_BUTTON_CLASS = '.js-media-window__close';
    this.PLAYING_CLASS = 'media-window--playing';
    this.VISIBLE_CLASS = 'media-window__player--visible';
    this.breakpoint =
      $(this.element).data('media-window-breakpoint') !== ''
        ? $(this.element).data('media-window-breakpoint')
        : 'tablet-up';
    this.letterboxPlayer = null;
    this.initMediaWindow();

    this.isLoading = false;
  }

  initMediaWindow() {
    // only init the media window for the required breakpoints (default: tablet-up = 720px)
    if ($(window).width() > 719 || this.breakpoint === 'mobile') {
      // init the media window
      if ($(MEDIA_HOOK, this.element).length > 0) {
        this.registerListeners();

        // disable letterbox player of contained media items
        $(MEDIA_HOOK, this.element).each(function (index, elem) {
          $(elem).attr('data-disable-internal-player', 1);
        });
      }
    }
  }

  registerListeners() {
    $(this.element).on('click touchstart', this.CLOSE_BUTTON_CLASS, (e) =>
      this.handleCloseButtonClick(e),
    );

    onEvent({
      eventName: MEDIA_CLICKED,
      eventHandler: ({ detail }) => this.handleClick(detail),
    });
    onEvent({
      eventName: MEDIA_PLAYING,
      eventHandler: ({ detail }) => this.handleMediaPlaying(detail),
    });

    // enabling esc-Key within media window (outside player)
    $('.js-media-window-container').on('keyup', (e) => {
      if (parseInt(e.which) === KEYCODES.escape) {
        this.handleCloseButtonClick(e);
      }
    });
  }

  handleMediaPlaying(data) {
    // Edge case: Quickly closing the MediaWindow again before the video is loaded used to play the video in the
    // background. When the isLoading flag is set, the video is immediately paused when it starts playing.
    if ((data.id && !this.isOwnItem(data.id)) || !this.isLoading) {
      this.pause();
    }
  }

  handleClick(data) {
    if (this.isOwnItem(data.id)) {
      // show media container for own children
      this.showMediaContainer();

      $('.media-still--active', this.element).removeClass(
        'media-still--active',
      );
      // hide icon and time indicator on still
      $(`#${data.id} .media-still`).addClass('media-still--active');

      if (this.letterboxPlayer == null) {
        this.letterboxPlayer = new SrfLetterboxPlayer({
          containerWrapperId: this.$playerContainer.attr('id'),
          assetId: data.assetId,
        });
      } else {
        this.letterboxPlayer.load(data.assetId, true);
      }
      this.isLoading = true;
    } else {
      this.pause();
    }
  }

  pause() {
    if (this.letterboxPlayer !== null) {
      this.isLoading = false;
      this.letterboxPlayer.pause();
    }

    if (this.$playerContainer.hasClass(this.VISIBLE_CLASS)) {
      this.hideMediaContainer();
    }

    $('.media-still', this.element).removeClass('media-still--active');
    $('.medium', this.element).removeClass(
      'medium--active js-medium-is-active',
    );
    $('.media-caption', this.element).removeClass('media-caption--active');
  }

  handleCloseButtonClick(e) {
    e.preventDefault();
    $(this.element).addClass('js-click-close-button');
    this.pause();
  }

  showMediaContainer() {
    // show the playercontainer again (was hidden so it can't be focussed/seen by screenreader)
    this.$playerContainer.css('display', '');
    this.hintBrowserAboutAnimation(this.$playerContainer, 'height');
    this.adjustOpeningPosition();
    let that = this;
    this.$playerContainer.on('transitionend', function () {
      if ($(that.element).hasClass(that.PLAYING_CLASS)) {
        // only show the video when the container is fully open
        $(this)
          .addClass(that.VISIBLE_CLASS)
          .closest('.js-media-window-container')
          .removeClass('js-window-opening');
      } else {
        $(this)
          .closest('.js-media-window-container')
          .removeClass('js-window-closing');
      }
    });
  }

  hideMediaContainer() {
    this.hintBrowserAboutAnimation(this.$playerContainer, 'height');
    this.adjustClosingPosition();
  }

  adjustClosingPosition() {
    let $currentWindow = $(this.element),
      $previousWindow = $('.' + this.PLAYING_CLASS);

    // "we" might just be opening or not being open at all :/
    if (
      $currentWindow.is($('.js-window-opening')) ||
      !$currentWindow.is($previousWindow)
    ) {
      // --> this method is called "near infinite" times :/
      return false; // "NO! we're just opening!!"
    }

    // keeping track of "things" :/
    $currentWindow.addClass('js-window-closing');

    // hide any "previous" video if gth closes
    this.$playerContainer
      // hide the playercontainer so it can't be focussed/seen by screenreader
      .one('transitionend', () => this.$playerContainer.css('display', 'none'))
      .removeClass(this.VISIBLE_CLASS);

    // the closing wasn't initiated by another GTH:
    if ($currentWindow.hasClass('js-click-close-button')) {
      // adjust position if we close the current gallery
      let scrollPosition = $(window).scrollTop() - this.getGTHHeight() / 2;
      $('html, body').animate(
        { scrollTop: scrollPosition + 'px' },
        300,
        'linear',
      );
    } // otherwise don't adjust anything:
    $currentWindow
      .removeClass(this.PLAYING_CLASS)
      // just in case:
      .removeClass('js-click-close-button')
      .data('GTH-current-position', $currentWindow.offset().top);
  }

  adjustOpeningPosition() {
    let $currentWindow = $(this.element),
      $previousWindow = $('.js-window-closing');

    if ($currentWindow.is($('.' + this.PLAYING_CLASS))) {
      // if the "two containers" are the same we don't want any adjustment to the position
      return false;
    }

    $currentWindow.addClass('js-window-opening');

    // where's the media-window from the top of the page
    let topPosition = $currentWindow.offset().top;

    // where are we now? (with the scrollbar)
    let scrollPosition = $(window).scrollTop();

    // and where from the top of the viewport
    let topPositionRelative = topPosition - scrollPosition;

    let windowHeight = window.innerHeight;

    let playerHeight = this.getGTHHeight();

    // a window might close above --> the positioning changes
    if (
      $previousWindow.length > 0 &&
      $previousWindow.data('GTH-current-position') < topPosition
    ) {
      // the previous window might be "visible"
      if (
        $previousWindow.data('GTH-current-position') + playerHeight >
        scrollPosition
      ) {
        // is there enough room for the opening player?
        if (topPositionRelative < playerHeight) {
          $('html, body').animate(
            { scrollTop: topPosition - playerHeight + 'px' },
            300,
            'linear',
          );
        } // else: do nothing (!), the above window will do "the deed"
      } else {
        // window above "out of sight"
        let playerHalf = playerHeight / 2;
        let windowGoTo = 0;
        // would the opening GTH be cut off at the top? (possibly: windowHeight < playerHeight)
        if (topPositionRelative - playerHalf < 0) {
          // --> GTH will be smack at the top of the viewport
          windowGoTo = topPosition - playerHeight;
        } else {
          // topPositionRelative - playerHalf >= 0 && // room at the top
          // topPositionRelative + playerHalf <= windowHeight // room at the bottom
          windowGoTo =
            scrollPosition -
            playerHalf +
            this.getOverlap(topPositionRelative, playerHalf, windowHeight);
        }
        $('html, body').animate(
          { scrollTop: windowGoTo + 'px' },
          300,
          'linear',
        );
      }
      // first start scrolling the page --> then start the transition (open the GTH)
      $currentWindow.addClass(this.PLAYING_CLASS);
      return false;
    }

    let playerHalf = playerHeight / 2;

    let windowGoTo = 0;
    // would the opening GTH be cut off at the top? (possibly: windowHeight < playerHeight)
    if (topPositionRelative - playerHalf < 0) {
      // move it (window = player) up to the top of the viewport
      windowGoTo = topPosition;
    } else {
      // scroll the window up half the players height (so it's position appears "centrally")
      windowGoTo =
        scrollPosition +
        playerHalf +
        this.getOverlap(topPositionRelative, playerHalf, windowHeight);
    }

    $('html, body').animate({ scrollTop: windowGoTo + 'px' }, 300, 'linear');
    // first start scrolling the page --> then start the transition (open the GTH)
    $currentWindow.addClass(this.PLAYING_CLASS);
  }

  // hints the browser about an upcoming animation and removes the hint after the transition
  // results in a performance improvement, especially in safari
  // see: https://dev.opera.com/articles/css-will-change-property/
  hintBrowserAboutAnimation(element, prop) {
    element.css('willChange', prop);
    element.one('transitionend', () => {
      element.css('willChange', '');
    });
  }

  getGTHHeight() {
    let windowWidth = $(window).width();

    // player-container heights: desktop-wide (min-width: 1279): 524 | tablet (min-width: 720): 448
    let playerHeight = 524; // desktop / desktop-wide
    if (windowWidth < 1279) {
      playerHeight = 448; // tablet
      if (windowWidth < 720) {
        playerHeight = windowWidth * 0.5625 + 90; // mobile
      }
    }
    return playerHeight;
  }

  getOverlap(topPositionRelative, playerHalf, windowHeight) {
    // how much "room" do we have at the bottom? (so we don't cut the gth off!)
    let overlap = topPositionRelative + playerHalf - windowHeight;
    return overlap > 0 ? overlap : 0; // only negative overlaps need to be considered
  }

  isOwnItem(elementId) {
    return $(this.element).find('#' + elementId).length > 0;
  }
}
