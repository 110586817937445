/**
 * Assets for Articles (Web)
 */
// styles
import '@aron/article/main.scss';

// common js
import { commonWeb } from '@aron/common/main.common.js';

// custom article modules
import { init as srfDateline } from '@fec/frontend/elements/text/article-author/srf-dateline.js';
import { init as sharingBarInit } from '@fec/frontend/modules/content/sharing-bar/sharing-bar.js';
import { init as srfQualityAssuranceInit } from '@aron/qa/qa.js';
import { init as mediaWindowInit } from '@fec/frontend/elements/media/media-window/media-window.js';
import { init as srfPlayNowButton } from '@aron/audio/radio-article-header/srf-play-now-button.js';
import { init as srfIframeInit } from '@aron/common/srf-iframe-adjust.js';
import { init as srfIframeModalInit } from '@aron/common/srf-iframe-modal.js';
import { init as srfIframeEventTrack } from '@aron/common/srf-iframe-event-track.js';
import { init as autoboxInit } from '@aron/article/autobox/autobox.js';
import { init as expandableBoxInit } from '@fec/frontend/modules/content/expandable-box/expandable-box.js';
import { init as reachBottom } from '@aron/common/analytics/reach-bottom.js';
import { init as textareaInit } from '@fec/frontend/elements/form/textarea/textarea.js';
import { init as srfTeaserMetaInit } from '@fec/frontend/elements/text/teaser-meta/teaser-meta.controller.js';
import { init as srfCommentsInit } from '@default/webview/js/srf-comments-api.js';

import '@fec/frontend/foundation/utils/easings.js';
import '@fec/frontend/modules/containers/modal/modal.js';

$(document).ready(function () {
  commonWeb();

  srfDateline();
  sharingBarInit();
  srfQualityAssuranceInit();
  mediaWindowInit();
  srfPlayNowButton();
  srfIframeInit();
  srfIframeModalInit();
  srfIframeEventTrack();
  autoboxInit();
  expandableBoxInit();
  reachBottom();
  textareaInit();
  srfTeaserMetaInit();
  srfCommentsInit();
});
